@import url('https://fonts.googleapis.com/css2?family=Lusitana&family=Raleway&family=Source+Code+Pro:wght@700&display=swap');

.App {
  text-align: center;
  
}

/* header
-----------------------------------*/
header {
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  background: #161415 url(../public/images/header-background.jpg) no-repeat top center;
  background-size: cover !important;
 -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

.home-banner {
  margin-top: 20%;
  color: white;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1.5px;

}

.icon-img-head {
  padding: 10px;
  max-width: 30px;
  max-height: 30px;
  fill: white;

  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

}

.icon-img-head:hover {
transition: 0.5s;
fill: black;
}

@media only screen and (max-width: 420px) {
  .home-banner {
    margin-top: 40%;
  }
}

/* navigation
-----------------------------------*/

#nav-wrap ul, #nav-wrap li, #nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

.nav-light {
  background-color: rgba(156, 147, 147, 0.5);
  /* background-color: #ebeeee32; */


}

.nav-dark {
  background-color: #161415;
  transition: background-color 0.25s linear;
}

.navHidden {
  opacity: 0;
  transition: 0.25s;
}

/* nav-wrap */
#nav-wrap {
  font-family: 'Raleway', sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

}
.opaque { background-color: #333; }

.stickBottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
}

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
  min-height: 48px;
  width: auto;
  /* z-index: 99; */
  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

/* Links */
ul#nav li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
 text-decoration: none;
  text-align: left;
  color: #fff;

 -webkit-transition: color .2s ease-in-out;
 -moz-transition: color .2s ease-in-out;
 -o-transition: color .2s ease-in-out;
 -ms-transition: color .2s ease-in-out;
 transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #F06000; animation-name: current; animation-duration: 2s;}

.btn a::before,
.btn a::after {
  display: inline-block;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
	-moz-transition: -moz-transform 0.3s, opacity 0.2s;
	transition: transform 0.3s, opacity 0.2s;
}


.btn a::before {
  margin-right: 10px;
	content: '[';
	-webkit-transform: translateX(20px);
	-moz-transform: translateX(20px);
	transform: translateX(20px);
}

.btn a:hover::before {
  transform: scaleY(0);
}

.btn a::after {
  margin-left: 10px;
	content: ']';
	-webkit-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	transform: translateX(-20px);
}

.btn a:hover::before,
.btn a:hover::after,
.btn a:focus::before,
.btn a:focus::after {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px);
}

@media only screen and (max-width: 420px) {
  ul#nav li {
    height: 18px;

  }

  #nav-wrap {
    padding-bottom: 10px;
  }

  ul#nav li a {
    padding-top: 5;
    padding-bottom: 5;
    padding-left: 0;
    padding-right: 0;
  }
}

.displayLine {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: auto;
  
}

/* Job
---------------------------------------------*/

#job {
  
  font-family: 'Lusitana', serif;
  text-align: center;
  justify-content: center;

  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
 padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
  
}

.job-img {

  width: 250px;
  align-items: center;
  border-radius: 50%;

}


/* Projects
---------------------------------------------*/
#projects {
  background: #ebeeee;
  padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 800px;
}

#projects h1 {
  /* font: 15px/24px; */
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: #95A3A3;
}

#projects-wrapper {
  margin-bottom: 36px;
}
.projects-wrapper > ul {
  display: none;
  height: 800px;
  /* gap: 50px; */
}

.projects-list {
  padding: 0;
  font-family: 'Lusitana', serif;

}

.projects-wrapper-animate > ul {
  margin: 0;
  /* text-align: center; */

  /* justify-items: center; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 2rem;
  /* padding-left: 0; */
  animation: slidein 2s;
  animation-fill-mode: forwards;

  transition-timing-function: ease-in;
  /* gap: 50px; */
}

.project-image {
  max-height: 200px;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.item-wrap {
  background-color: white;
  display: inline-grid;
  /* padding: 50px; */
  text-align: center;
  border: solid;
  border-radius: 1rem;
  height: 300px;

}

.projects-item {
  position: relative;
  
}

.projects-item h2 {
  font-family: 'Raleway', sans-serif;
  position: relative;
  bottom: 20px;
}

.mobile-chat-header {
  /* position: relative;
  top: 10px; */
  margin-top: 20%;
}
.projects-link {
  color: #7FA1DE;
  text-decoration: none;
 
}

.projects-link:hover {
  color: #c4d6f7;
  transition: 0.5s;
}

.details-button {
  position: absolute;
right:    0;
bottom:   0;
  height: 40px;
  width: 100%;
  color: white;
  font-family: 'Raleway', sans-serif;
  
  letter-spacing: 1.5px;
  background-color: #7FA1DE;
  border: 0;
  border-color: #7FA1DE;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.details-button:hover {
  background-color: #8cb2f7;
  transition: 0.5s;
}

.team-blurb {
  margin-top: 50px;
  font-size: large;
}

.team-blurb a {
  color: #7FA1DE;
  text-decoration: none;
}

.team-blurb a:hover {
  color: #b2caf7;
  text-decoration: none;
  transition: 0.5s;
}

@keyframes slidein {
  from {
    transform: translateX(-120%);
  }

  to {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 420px) {
  .projects-wrapper-animate > ul {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

}

/* Modal
--------------------------------------------------------------------------------------------*/

.overlay-base {
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  /* transition-property: background-color, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out; */

  transition: all 0.75s;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-after {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.content-base {
  position: relative;

  top: auto;
  left: auto;
  right: auto;
  bottom: auto;

  margin: 0 auto;
  border: 0;
  border-radius: 1rem;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 500px;
  /* background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out; */
}

.modal-container {
  padding: 10px;
}

.modal-header {
  font-family: 'Raleway', sans-serif;
}

.modal-body {
  font-family: 'Lusitana', serif;
}

.content-after {
  width: 800;
  height: 800;
  background-color: rgba(250, 190, 190, 0.8);
}

.content-before {
  width: 11%;
  height: 0%;
  background-color: transparent;
}

.modal-close {
  position: relative;
  top: -70px;
  right: 5px;
  background-color: #F06000;
  /* z-index: 99; */
}

/*hide liveLink for mobile-chat*/
a[href="mobile-chat.png"] {
  display:none;
}

.modal-image {
  width: 70%;
  margin-bottom: 5px;

}

.modal-image-mobile {
  max-height: 200px;
  margin-bottom: 5px;

}

/* Skills
-----------------------------------*/
#skills {
  
  text-align: center;
  justify-content: center;

  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
 padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 800px;
  
}

.skills-header {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 2.5px;
  font: 15px/24px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 48px;
  color: #95A3A3;
}

.row-skill {
  background-color: #ebeeee;
  padding-left: 25px;
  padding-right: 25px;
}


.education-img {
  max-width: 500px;
  max-height: 200px;
  padding: 0px 25px;
  position: relative;
  right: 40px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  z-index: -1;
}

.responsive-img {
  position: relative;
  right: 50px;
  margin-top: 24px;
  width: 100%;
  height: auto;
  padding: 0px 25px 0px;
  z-index: -1;
}

.education-text {
  font-family: 'Lusitana', serif;
  text-align: left;
  /* margin-left: 35px; */
  padding: 0px 25px;
}

.education-text h3 {
  font-family: 'Raleway', sans-serif;
}

.education-content {
  padding: 0px 5px;
}

.skillsLine{
  
    display: none;
  }

  @media only screen and (max-width: 740px) {
    .skillsLine {
    display: block;
    
    margin: 40px 0 !important;
    height: 1px;
    width: 100%;
    border: 0;
    border-top: 1px solid #ccc;
   
    padding: 0;
    }
  }

.bar-header {
  font-family: 'Raleway', sans-serif;
  text-align: left;
  padding-left: 10px;
}


.progress {
  background: black;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 2px;
  display: flex;
  height: 40px;
  /* width: 500px; */
   border: 0.25px;
  border-color: white;
  z-index: -1;
}

.progress-html {
  animation: html 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #7FA1DE;
  height: 36px;
  width: 0;
   border: 1px;
  border-color: white;
  z-index: 1;
}

@keyframes html {
  0% { width: 0; }
  100% { width: 80%; }
}

.progress-css {
  animation: css 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #7FA1DE;
  height: 36px;
  width: 0;
   border: 1px;
  border-color: white;
}

@keyframes css{
  0% { width: 0; }
  100% { width: 85%; }
}

.progress-javascript {
  animation: javascript 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #7FA1DE;
  height: 36px;
  width: 0;
   border: 1px;
  border-color: white;
}

@keyframes javascript{
  0% { width: 0; }
  100% { width: 65%; }
}

.progress-reactjs {
  animation: reactjs 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #7FA1DE;
  height: 36px;
  width: 0;
   border: 1px;
  border-color: white;
}

@keyframes reactjs{
  0% { width: 0; }
  100% { width: 70%; }
}

.progress-reactnative {
  animation: reactnative 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #7FA1DE;
  height: 36px;
  width: 0;
   border: 1px;
  border-color: white;
}

@keyframes reactnative{
  0% { width: 0; }
  100% { width: 55%; }
}

.progress-angularjs {
  animation: angularjs 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #7FA1DE;
  height: 36px;
  width: 0;
   border: 1px;
  border-color: white;
}

@keyframes angularjs{
  0% { width: 0; }
  100% { width: 65%; }
}

/* About
---------------------------------*/
.about {
  font-family: 'Lusitana', serif;
  text-align: center;
  justify-content: center;

  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
 padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
}

.about h1 {
  font-family: 'Raleway', sans-serif;
}


.about-img {

    width: 250px;
    align-items: center;
    border-radius: 50%;

}

/* Contact
--------------------------------------*/

#contact {
  text-align: center;
  justify-content: center;

  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */

 padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
}

.contact-text a {
  color: #7FA1DE;
  text-decoration: none;
}

.contact-text a:hover {
  color: #b2caf7;
  text-decoration: none;
  transition: 0.5s;
}

.contact-text {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.contact-left {
  list-style-type:none;
  text-align: left;
}

.contact-right {
  list-style-type:none;
  text-align: left;
}

.contact-block {
  display: inline-block;
  flex: 1;
  min-width: 150px;
  max-width: fit-content;
  word-break: break-all;
}

.footer {

  
  
  text-align: center;
  justify-content: center;
  /* padding: 50px; */
}

.icon-img {
    padding: 10px;
    max-width: 30px;
    max-height: 30px;


    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

}

.iconUp-img {
  padding: 10px;
  max-width: 30px;
  max-height: 30px;
  fill: white;
  margin-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

}

.iconUp-img:hover {
  transition: 0.5s;
  fill: #7FA1DE;
}

.icons-container {
  padding: 30px;
}

.icon-img:hover {
  transition: 0.5s;
  fill: white;
}

.copywright-container {
  
  background-color: black;
  padding: 40px;
  justify-content: center;
  
}

.copywrightText {
  color: white;
  margin: 5px auto;
  
}